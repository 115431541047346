// used to mark unsupported tokens, these are hosted lists of unsupported tokens

export const UNSUPPORTED_LIST_URLS: string[] = []
export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
export const AVALANCHE_LIST =
  'https://raw.githubusercontent.com/RamsesExchange/ramses-assets/main/blockchains/avalanche/tokenlist.json'
export const MANTLE_LIST =
  'https://raw.githubusercontent.com/RamsesExchange/ramses-assets/main/blockchains/mantle/tokenlist.json'
export const LINEA_LIST =
  'https://raw.githubusercontent.com/RamsesExchange/ramses-assets/main/blockchains/linea/tokenlist.json'
export const ARBITRUM_LIST =
  'https://github.com/RamsesExchange/ramses-assets/blob/main/blockchains/arbitrum/tokenlist.json'
export const POLYGON_LIST =
  'https://unpkg.com/quickswap-default-token-list@1.2.2/build/quickswap-default.tokenlist.json'
export const CELO_LIST = 'https://celo-org.github.io/celo-token-list/celo.tokenlist.json'
export const BNB_LIST = 'https://raw.githubusercontent.com/plasmadlt/plasma-finance-token-list/master/bnb.json'
//TODO
// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  LINEA_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [LINEA_LIST] //TODO
